<style scoped></style>

<template>
  <div style="background-color: #FFF;padding: 20px;border-radius: 5px;margin-bottom: 10px;">
    <Steps style="margin: 0 auto;width: 50%;min-width: 700px;" :current="stepCurrent">
      <Step :title="status === 'empty' ? '未开始' : '准备中'"></Step>
      <Step title="计算中"></Step>
      <Step title="分配中"></Step>
      <Step title="已发布"></Step>
    </Steps>
  </div>
</template>

<script>
export default {
  name: 'MonthSteps',
  props: {
    status: { type: String, default: 'empty' }
  },
  computed: {
    stepCurrent () {
      const status = this.status
      return ['plan', 'compute', 'divide', 'end'].findIndex(v => v === status)
    }
  }
}
</script>
