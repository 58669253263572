<style scoped lang="less">
  .pfm-month-menu-page {
    display: flex;
    flex-direction: column;
  }
  .plan-menus {
    flex: 1;
    height: 0;
    overflow: auto;
    display: flex;
    flex-direction: row;
    padding: 5px;
    &.multiple {
      flex-wrap: wrap;
      align-content: flex-start;
      .wrap {
        width: 20%;
        flex: unset;
      }
    }
    .wrap {
      flex: 1;
      padding: 5px;
    }
  }
  .plan-menu {
    user-select: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #EEE;
    border-radius: 5px;
    background-color: #FFF;
    height: 150px;
    &.role {
      cursor: default;
    }
    &:hover {
      border: 1px solid #1cb5e0;
      .iconfont, span {
        color: #1cb5e0;
      }
    }
    &, .iconfont, span {
      transition: all .3s;
    }
    .iconfont {
      font-size: 40px;
    }
  }
  .btns {
    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 10px;
    }
  }
  .title {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    margin: 0 10px 10px 10px;
    border-radius: 5px;
    background-color: #FFF;
    flex: 1;height: 0;display: flex;flex-direction: column;
  }
</style>

<template>
  <div class="pfm-month-menu-page">
    <MonthSteps style="margin: 10px;" :status="data.status" />

    <div class="content">
      <div class="title">
        <fm-title :title-text="'月度绩效详情 - ' + data.month"></fm-title>
        <div class="btns">
          <Button @click="back">返回</Button>
          <Button :loading="loading.status" @click="output" v-if="data.status === 'end' && $authFunsProxyNoRoute['performance.calculate.index-manage']">导出发放结果</Button>
          <template v-if="roleTypes.includes('manage')">
            <Poptip v-if="data.status === 'plan'" confirm @on-ok="statusSwitch('compute')" placement="bottom" title="确定开始计算吗？">
              <Button :loading="loading.status" type="primary">开始计算</Button>
            </Poptip>
            <Poptip v-if="data.status === 'compute'" confirm @on-ok="statusSwitch('divide')" placement="bottom" title="确定开始分配吗？">
              <Button :loading="loading.status" type="primary">开始分配</Button>
            </Poptip>
            <Poptip v-if="data.status === 'divide'" confirm @on-ok="statusSwitch('end')" placement="bottom" title="确定发布吗？">
              <Button :loading="loading.status" type="primary">发布</Button>
            </Poptip>
            <Poptip v-if="data.id && data.status === 'plan'" confirm @on-ok="itemDel" placement="bottom" title="确定删除吗？">
              <Button :loading="loading.status" type="primary">删除月度绩效</Button>
            </Poptip>
          </template>
        </div>
      </div>

      <div class="plan-menus scrollbar smallbar" :class="{multiple: menus.length > 5}">
        <div class="wrap" v-for="(menu, index) in menus" :key="index + '-' + menu.key">
          <div class="plan-menu" @click="menuClick(menu)">
            <i :class="'iconfont ' + menu.icon"></i>
            <span>{{menu.label}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'
import { getEmptyMonthItem, detailMenus } from '../lib'
import MonthSteps from './steps.vue'

import {
  fileHepler
} from '@/fmlib'

export default {
  components: { MonthSteps },
  data () {
    return {
      data: getEmptyMonthItem(this.$datetime.format(new Date(), 'Y-M-D')),
      loading: {
        status: false
      }
    }
  },
  computed: {
    monthId () {
      return this.$route.query.id
    },
    menus () {
      const roleTypes = this.roleTypes
      const status = this.data.status
      return detailMenus.filter(btn => {
        return !btn.routerOnly && btn.level === 0 && btn.status.includes(status) && (!btn.roles || !btn.roles.length || btn.roles.some(v => roleTypes.includes(v)))
      })
    },
    roleTypes () {
      const types = []
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage']) {
        types.push('manage')
      }
      if (this.$authFunsProxyNoRoute['performance.calculate.index-operation']) {
        types.push('operation')
      }
      if (this.$authFunsProxyNoRoute['performance.calculate.index-view']) {
        types.push('view')
      }
      if (this.$authFunsProxyNoRoute['performance.calculate.index-editor']) {
        types.push('editor')
      }
      return types
    }
  },
  watch: {
    monthId () {
      if (this.$route.name === 'performance.calculate.details.menu') {
        this.loadData()
      }
    }
  },
  methods: {
    async output () {
      this.loading.status = true
      const res = await request('/performance/pfm_worker_month/result?pfmMonthId=' + this.monthId)
      let dataList = res.map((v, i) => {
        return {
          index: i + 1,
          pfmWorkerName: v.pfmWorkerName,
          pfmUnitName: v.pfmUnitName,
          pfmGzl: v.pfmUnitMonthType === 'other' ? v.avgValue : v.unitValue,
          singleValue: v.singleValue,
          total: v.total
        }
      })
      let columnList = [{
        title: '序号',
        field: 'index'
      },
      {
        title: '姓名',
        field: 'pfmWorkerName'
      },
      {
        title: '核算单元',
        field: 'pfmUnitName'
      },
      {
        title: '普通工作量绩效',
        field: 'pfmGzl'
      },
      {
        title: '重点项目绩效',
        field: 'singleValue'
      },
      {
        title: '合计',
        field: 'total'
      }]
      fileHepler.outPutTableData(dataList, columnList, this.data.month + '发放结果.xls')
      this.loading.status = false
    },
    async loadData () {
      const res = await request('/performance/pfm_month?id=' + this.monthId)
      if (!res.length) {
        this.$Message.error('数据不存在')
        return this.$router.back()
      }
      this.data = res[0]
    },
    async statusSwitch (status) {
      if (status === 'end') {
        let submitData = await request('/performance/pfm_unit_month', 'get', {
          pfmMonthId: this.data.id
        })
        let notSubmitData = submitData.filter(v => v.status === 'doing')
        if (notSubmitData.length > 0) {
          const result = await this.$dialog.confirm({title: '系统提示', content: '部分考核单元分配未提交，确定继续发布吗?'})
          if (!result) {
            return
          }
        }
      }
      
      this.loading.status = true
      try {
        await request('/performance/pfm_month/status/' + this.data.id, 'post', { status })
        this.$Message.success('操作成功')
        this.loadData()
        this.$emit('reload')
      } catch (error) {
        this.$Modal.error({ title: '操作失败', content: error.message })
        console.error(error)
      }
      this.loading.status = false
    },
    back () {
      this.$router.back()
    },
    async itemDel () {
      if (!this.data.id) {
        return
      }
      this.loading.status = true
      try {
        await request('/performance/pfm_month/' + this.data.id, 'delete')
        this.$Message.success('已删除')
        this.$emit('reload')
        this.$router.back()
      } catch (error) {
        this.$Modal.error({ title: '删除失败', content: error.message })
        console.error(error)
      }
      this.loading.status = false
    },
    menuClick (menu) {
      if (menu.url) {
        this.$router.push({
          name: menu.url,
          query: {
            monthId: this.data.id
          }
        })
      } else {
        this.$Message.error('暂不支持')
      }
    }
  },
  mounted () {
    this.loadData()
  },
}
</script>
